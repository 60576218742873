// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awareness-js": () => import("./../../../src/pages/awareness.js" /* webpackChunkName: "component---src-pages-awareness-js" */),
  "component---src-pages-butik-subscribe-success-js": () => import("./../../../src/pages/butik-subscribe-success.js" /* webpackChunkName: "component---src-pages-butik-subscribe-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-zanzibar-js": () => import("./../../../src/pages/zanzibar.js" /* webpackChunkName: "component---src-pages-zanzibar-js" */),
  "component---src-pages-zanzibar-subscribe-success-js": () => import("./../../../src/pages/zanzibar-subscribe-success.js" /* webpackChunkName: "component---src-pages-zanzibar-subscribe-success-js" */)
}

